.main_video_container {
    flex-grow: 1;
    min-height: 90vh;
}

.logo_container {
    padding-left: 2%;
    flex-grow: 8;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 2vh;
}

.logo_description {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.log_image {
    width: 110px;
    height: 38px;
}

.video_description {
    margin-left: 5vh;
    display: flex;
    flex-direction: column;
}

.video_name {
    font-size: 18px;
    font-weight: 600;
    margin: 2px;
}

.video_info {
    font-size: 16px;
    font-weight: 600;
    color: #ababab;
}

.block_title {
    display: flex;
    align-items: center;
    width: 98%;
    height: 70px;
    margin: 0 auto;
    font-weight: 500;
    font-size: 28px;
}

.block_title.small {
    width: 35vh;
    font-size: 22px;
}

/*.main_video_container > div {*/

/*    display: flex;*/

/*    flex-grow: 1;*/

/*}*/
