.participant_container {
    height: 96vh;
    display: flex;
    max-width: 40vh;
    min-width: 40vh;
    flex-direction: column;
    align-items: center;
}

.participant_control_container {
    padding: 30px 20px;
    margin-top: 15px;
    border-radius: 6px;
    width: 85%;
    background-color: #2E353E;
}

.participant_control_container>h2 {
    font-size: 30px;
    font-weight: 500;
    margin: 0;
}

.participant_control_container>p {
    margin: 20px 0;
    font-size: 16px;
}

.participant_control {
    display: flex;
    flex-direction: row;
    width: 85%;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 5px;
    border-radius: 25px;
    background-color: #18232B;
    font-size: 12px;
}

.participant_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    background-color: #18232B;
    color: #f9f9f9;
    background-position: center;
    background-size: 25px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.participant_icon.lightning {
    background-image: url('../../assets/lightning_inactive.png');
}

.participant_icon.lightning.active {
    background-image: url('../../assets/lightning_active.png');
}

.participant_icon.smile {
    background-image: url('../../assets/smile_inactive.png');
}

.participant_icon.smile.active {
    background-image: url('../../assets/smile_active.png');
}

.participant_icon.phone {
    background-image: url('../../assets/phone_inactive.png');
}

.participant_icon.phone.active {
    background-image: url('../../assets/phone_active.png');
}

.participant_icon.home {
    background-image: url('../../assets/home_inactive.png');
}

.participant_icon.home.active {
    background-image: url('../../assets/home_active.png');
}

.active {
    border: 1px solid #f9f9f9;
    background-color: #f9f9f9;
    color: #282c34;
}
