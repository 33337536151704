.emoji_container{
    justify-self: flex-end;
    width: 400px;
    height: 50px;
    display: flex;
    border-radius: 5px;
    padding-left: 1%;
    background-color: #2E353E;
    padding-right: 1%;
    margin-right: 15px;
}
.emoji_button {
    font-size: 40px;
    width: 50px;
    height: 50px;
    cursor: pointer;

}
.emoji_button:hover{
    /*font-size: 50px;*/
}
.active-emoji img {
    width: 50px;
    height: 50px;
}
.emoji_img {
    width: 40px;
    height:40px;
}
.emoji_img:hover {
    width: 50px;
    height:50px;
}
