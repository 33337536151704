/*video {*/
/*    position: absolute;*/
/*    top: -10vh;*/
/*    height: 50vh;*/
/*    background-color: black;*/
/*     object-fit: cover!important;*/
/*    overflow: hidden;*/
/*}*/

/*.video-container {*/
/*    position: relative;*/
/*    width: 35vh;*/
/*    height: 200px;*/
/*    margin: 20px 0 0 0;*/
/*    border-radius: 7px;*/
/*    overflow: hidden;*/
/*}*/


video {
    width: 35vh;
    height: 200px;
    border-radius: 7px;
    /*object-fit: cover;*/
}
.video-container {
    position: relative;
    border-radius: 7px;
}
.control-video-block {
    position: absolute;
    /*height: 30px;*/
    width: 100px;
    bottom: 5px;
    font-weight: bold;
    font-size: 17px;
    display: flex;
    border-radius: 0 5px 0 0;
}

.participant-camera {
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.participant-camera img {
    width: 20px;
    height: 20px;
}

.participant-mic {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.participant-mic img {
    width: 20px;
    height: 20px;
}

.active-speaker {
    border: 1px solid #ffe484;
}
